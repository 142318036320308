.ListingDetailPage {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
    margin-right: 32px;
}

.jcsa {
    display: flex;
    justify-content: space-around;
}

.jcsb {
    display: flex;
    justify-content: space-between;
}

.row {
    display: flex;
    flex-direction: row;
}

.col {
    display: flex;
    flex-direction: column;
}

.p8 {
    padding: 8px;
}

.p4 {
    padding: 4px;
}

.w20p {
    width: 10%;
}

.minW250 {
    min-width: 250px;
}

.w100p {
 width: 100%;
}