    .image-gallery-slide .image-gallery-image {
    object-fit: contain !important;
    padding-left: 80px;
    padding-right: 80px;
}

.image-gallery {
    height: 100% !important;
}

.fullscreen {
    height: 100% !important;
}

.image-gallery-slide-wrapper {
    height: 100% !important;
}

.image-gallery-swipe {
    height: 100% !important;
}

.image-gallery-left {
    height: 100% !important;
}

.image-gallery-right {
    height: 100% !important;
}

.image-gallery-slides {
height: 100%;
    justify-items: center;
    justify-content: center;
    display: flex;
}