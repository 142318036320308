.ListingSearchPage {
  display: flex; /* or inline-flex */
  flex-direction: row;
  justify-content: space-between
}

.w20p {
  width: 20%;
}

.p16 {
  padding: 16px;
}

.bgrTeal {
  background-color: #008080;
}

.section {
  margin-left: 30px;
  margin-right: 30px;
}

.footer-wrap {
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
}

/* .selected {
  background-color: teal;
}

.next {
  background-color: teal;
}

.previous {
  background-color: teal;
}

.disabled {
  background-color: lightgrey;
} */

.issuesPagination .selected a {
  box-shadow: 0 0 2px #1b7d9e;
  background-color: #f3fcff;
}

.issuesPagination .disabled > a {
  color: #ccc;
  background-color: #f8f8f8;
  border-color: #eee;
  cursor: default;
}

.issuesPagination .disabled > a:hover {
  background-color: #f8f8f8;
}
.issuesPagination .break {
  margin: 0 8px;
}

.issuesPagination .previous {
  margin-right: 1rem;
}

.issuesPagination .next {
  margin-left: 1rem;
}


