@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.LatoText {
    font-size: 24px;
    margin: 1.5rem 0;
    font-family: "Lato", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.MontserratText {
    color: rgb(76, 175, 80);
    font-weight: 700;
    font-size: 48px;
    margin-bottom: 1rem;
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    
}