.ListingCardBase {
  padding-top: 16px;
}

.css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root {
  display: flex !important;
  width: 100%;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.p8 {
  padding: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.pr-16 {
  padding-left: 16px;
}

.w50p {
  width: 50%;
}

.jcsb {
  justify-content: space-between;
}

.h100p {
  height: 100%;
}